.initial {
    border: 2px solid #ffffff;
    padding: 1rem;
    display: block;
    align-items: center;
    background-color: #F5F7FA;
    width: 100%;
    transition: border-color 0.25s ease-in;
    position: relative;
  }
  
  .initial-body {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-flow: wrap;
  }
  
  .initial-body-left {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 0.75rem;
    /* flex-flow: wrap; */
  }
  
  .initial-body-left-line {
    display: flex;
    /* flex-flow: wrap; */
    align-items: center;
    min-height: 107px;
    /*grid-gap: 1.5rem;*/
    /* width: 50% */
  }
  
  .initial-body-left-line-outer {
    display: flex;
    align-items: center;
    width: 368px;
    /*width: 23rem ;*/
  }
  
  .initial-body-right {
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 1.25rem;
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
  }
  
  .initial-p-heading-text {
    display: flex;
    flex-wrap: wrap;
  }
  
  .initial-p-heading-font {
    font-weight: 600;
  }
  .pywinitial-sdk {
    display: flex;
    justify-content: center;
    /*font-weight: bold;*/
    font-size: 0.85rem;
    color: #9199a1;
  }
  
  .pywinitial-sdk img {
    margin-top: 0rem;
    width: 0.65rem;
    height: auto;
    margin-right: 0.375rem;
  }
  .pywinitial-card {
    /*border: 2px solid #e7ebee;*/
    border-radius: 0.5rem;
    /*padding: 1rem;*/
    /*display: flex;*/
    align-items: center;
    /*grid-gap: 1rem;*/
    background-color: transparent;
    width: 100%;
    transition: border-color 0.25s ease-in;
    position: relative;
  }
  
  .pywinitial-card.selected {
    border-color: #1668e3;
  }
  
  .pywinitial-card.no-select {
    justify-content: center;
    /*grid-gap: 2rem; */
  }
  
  .pywinitial-card .master-card-img img {
    margin-top: -1.5rem;
    width: 6rem;
    height: auto;
  }
  
  .pywinitial-card .description {
    text-align: left;
    margin-left: 2rem;
  }