.pyw-apply-points-wrapper {
    .input-box {
      display: inline-flex;
      align-items: center;
  
      .prefix {
        font-weight: 700;
        font-size: 72px;
        width: 45%;
        text-align: right;
      }
      .points-input {
        font-weight: bold;
        font-size: 72px;
        line-height: 80px;
        color: #262c2f;
        border: 0;
        text-align: left;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        line-height: 98.05px; // This may need review, as line-height is declared twice.
  
        &:focus {
          outline: 0;
        }
      }
    }
  
    @media screen and (max-width: 495px) {
      .points-input-box {
        margin-top: 22px;
      }
    }
  }