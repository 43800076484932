.pyw-home-wrapper {
    // width: 405px;
    // display: flex;
    // align-items: center;
    // padding-top: 1rem;
    .container-wrapper-without-padding{
      width: 100%;
    }
    .hs-logo {
      width: 10rem;
    }
    .home-body {
      width: 405px;
      .home-merchant {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin-top: 14px;
        border: 2px solid #e2e3e3;
        padding: 9px;
        width: 100%;
        border-radius: 10px;
      }
      .input-text {
        padding: 12px 9px 12px 15px;
      }
    }
    .selection-card-member {
      border: 2px solid #e7ebee;
      border-radius: 0.5rem;
      padding: 16px;
      align-items: center;
      background-color: transparent;
      width: 100%;
      transition: border-color 0.25s ease-in;
      position: relative;
  
      &.selected {
        border-color: #1668e3;
      }
  
      &.no-select {
        justify-content: center;
      }
  
      .description {
        text-align: left;
        margin-left: 43.4px;
      }
      .spc-character-without-font-fusion {
        font-weight: 600;
        margin-left: 1px;
        bottom: 2px;
        position: relative;
      }
  
      @media screen and (max-width: 494px) {
        .ml-117 {
          margin-left: 0;
        }
        .home-description {
          margin-left: 15.6px;
        }
      }
    }
  }