.progressbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    position: relative;
    height: 48px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  
    &-step-select,
    &-step-disable,
    &-step-disable-left {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px;
      gap: 8px;
      width: 30%;
      height: 48px;
      border-radius: 8px;
      align-self: stretch;
      flex-grow: 1;
    }
  
    &-step-select {
      background: #ffffff; // Grey/White Color
      border: 2px solid #ebedf2; // Grey/Secondary
      order: 0;
    }
  
    &-step-disable,
    &-step-disable-left {
      background: #f5f7fa; // Grey/App background Color
    }
  
    &-step-disable {
      order: 1;
    }
  
    &-step-disable-left {
      order: 0;
    }
    .dot {
      height: 25px;
      width: 25px;
      background-color: #008232;
      border-radius: 50%;
      display: inline-block;
    }
    .checkmark {
      display: inline-block;
      width: 22px;
      height: 22px;
      -ms-transform: rotate(45deg); /* IE 9 */
      -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
      transform: rotate(45deg);
    }
  
    .checkmark_stem {
      position: absolute;
      width: 3px;
      height: 14px;
      background-color: #fff;
      left: 14px;
      top: 3px;
    }
  
    .checkmark_kick {
      position: absolute;
      width: 8px;
      height: 3px;
      background-color: #fff;
      left: 8px;
      top: 14px;
    }
  }
  header.main-header {
    background-color: #0066cc;
    width: 100%;
    height: 3.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  
    &.sh {
      border-radius: 0.5rem 0.5rem 0 0;
    }
  }
  
  .header-text {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .merchant {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-top: 13.5px;
    padding-bottom: 13.5px;
    height: 47px;
  
    .avatar_round {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: #ffffff;
    }
  
    .merchant-image {
      line-height: 20px;
      font-size: 0.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
  
      img {
        margin-right: 10px;
      }
    }
  
    .merchant-amount {
      font-size: 0.875rem;
      font-weight: 500;
      background-color: white;
      border-radius: 1.25rem;
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
  
      img {
        margin-right: 8.58px;
        margin-bottom: 1.1px;
      }
    }
  
    @media screen and (max-width: 495px) {
      /* Adjustments for mobile view if necessary */
    }
  }
  .user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ebedf2;
    padding: 0.75rem 1.5rem;
  
    .user-image {
      display: flex;
      justify-content: center;
      align-items: center;
  
      img {
        margin-right: 0.75rem;
        width: 40px;
        height: 40px;
      }
    }
    .counter-points {
      padding: 7px;
      font-size: 0.875rem;
      font-weight: 600;
      background-color: white;
      border-radius: 1.25rem;
      display: flex;
      justify-content: space-around;
    }
    .coin-logo {
      width: 16px;
      height: 16px;
      top: 7px;
      bottom: 7px;
      background-color: #0066cc;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .path-img {
      height: 7px;
      width: 8.470587730407715px;
      left: 4px;
      top: 4.5px;
      border-radius: 0px;
      left: 25%;
      right: 22.06%;
      top: 28.12%;
      bottom: 28.12%;
    }
  
    @media screen and (max-width: 495px) {
      padding: 0.75rem 1rem;
    }
  }
  .price-detail {
    padding: 0 1.5rem;
  
    @media screen and (max-width: 495px) {
      padding: 0 1rem;
    }
  
    .price-detail-item {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: 0;
      padding: 0.88rem 0;
      border-top: 1px solid #e2e3e3;
  
      &:first-child {
        border-top: 0;
      }
  
      &.pb-10 {
        padding-bottom: 10px;
      }
  
      dt,
      dd {
        color: black;
        font-size: 0.9375rem;
        font-weight: 500;
        display: flex;
  
        &.fs-14 {
          font-size: 14px;
        }
  
        img {
          margin-right: 0.5rem;
        }
      }
    }
  }
  @-webkit-keyframes spinner-border {
    to {
      transform: rotate(360deg) /* rtl:ignore */;
    }
  }
  
  @keyframes spinner-border {
    to {
      transform: rotate(360deg) /* rtl:ignore */;
    }
  }
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: 0.75s linear infinite spinner-border;
    animation: 0.75s linear infinite spinner-border;
  }
  
  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }
  
  @-webkit-keyframes spinner-grow {
    0% {
      transform: scale(0);
    }
    50% {
      opacity: 1;
      transform: none;
    }
  }
  
  @keyframes spinner-grow {
    0% {
      transform: scale(0);
    }
    50% {
      opacity: 1;
      transform: none;
    }
  }
  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: 0.75s linear infinite spinner-grow;
    animation: 0.75s linear infinite spinner-grow;
  }
  
  .spinner-grow-sm {
    width: 1rem;
    height: 1rem;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .spinner-border,
  .spinner-grow {
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
    }
  }