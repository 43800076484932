.confirm-footer {
  width: 448px;
  margin: auto;
  .terms-confirm-page {
    font-size: 10px;
    line-height: 14px;
    color: #262c2f;
    font-style: italic;
    margin-top: 1rem;
  }
}
.univ-center {
  text-align: center;
}
@media screen and (max-width: 495px) {
  .univ-center {
    text-align: left;
  }
  .confirm-footer {
    width: 100%;
  }
}
.activation-wrapper {
  .container-wrapper-without-padding {
    width: 400px;
  }
}
