.pyw-member-wrapper {
    .banner {
      .br-welcome-msg {
        display: none;
      }
  
      @media screen and (max-width: 494px) {
        .br-welcome-msg {
          display: inherit;
        }
      }
    }
    .container-wrapper-without-padding {
      .selection-card-member {
        border: 2px solid #e7ebee;
        border-radius: 0.5rem;
        padding: 16px;
        align-items: center;
        background-color: transparent;
        width: 100%;
        transition: border-color 0.25s ease-in;
        position: relative;
  
        &.selected {
          border-color: #1668e3;
        }
  
        &.no-select {
          justify-content: center;
        }
  
        .description {
          text-align: left;
          margin-left: 43.4px;
        }
        .spc-character-without-font-fusion {
          font-weight: 600;
          margin-left: 1px;
          bottom: 2px;
          position: relative;
        }
  
        @media screen and (max-width: 494px) {
          .ml-117 {
            margin-left: 0;
          }
          .home-description {
            margin-left: 15.6px;
          }
        }
      }
    }
  }
  