.hs-description-wrapper {
  .container-sh {
    padding: 1.5rem 0 1.5rem 0;
  }
  .hs-shsdesc-stjaap {
    font-size: 16px;
    line-height: 20px;
    font-style: normal;
    font-weight: 600;
    color: #000000;
  }
  .hs-shsdesc-pwsyw {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }

  .hs-shsdesc-st-opt {
    font-size: 16px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
    color: #000000;
  }

  .hs-shsdesc-span-email {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #909ba0;
  }
  .hs-shsdesc-wfpc {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    display: flex;
    align-items: center;
  }
  .hs-shsdesc-url {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #262c2f;
  }
  .hs-shsdesc-decline {
    color: #262c2f;
  }
  .label-email {
    position: relative;
    height: 50px;
  }
  .input-email {
    border-radius: 6px;
    border: 1px solid #bfc9d4;
    padding: 15px 45px 15px 15px;
    width: 100%;
    &.error-border{
      border: 1px solid red;
    }
    &.valid-border{
      border: 1px solid #34c06b;
    }
  }
  .input-email:focus {
    border: none;
  }


  .span-email {
    position: absolute;
    color: #909ba0;
    top: 0px;
    left: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    max-width: 12.5rem;
  }

  .input-email:focus + .span-email,
  .input-email:valid + .span-email {
    visibility: hidden;
  }

  .alert-sh {
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease-in;
    height: 0;
    max-height: 0;
    margin-top: 0;
    overflow: hidden;
    margin-left: -7px;
  }

  .alert-sh.show {
    height: auto;
    max-height: 100%;
    opacity: 1;
    visibility: visible;
    margin-top: 1.5rem;
  }
  .hs-shs-desc-line-separator-full {
    margin: 0.3rem 0;
    display: flex;
    border-top: 1px solid #ebedf2;
    flex: 1;
    align-items: center;
    grid-gap: 0.75rem;
  }
  .hs-shs-desc-total-url {
    color: #909ba0;
  }
}
.hs-confirm-wrapper {
}
.hs-order-confirm-wrapper {
  .cl-order-confirm {
    display: flex;
    height: 100%;
    max-width: 31rem;
    max-height: 42.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
  }

  .paymentLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .syw-vector {
    height: 65px;
    width: 65px;
    background-color: #fff;
    border-radius: 50%;
    filter: drop-shadow(0px 2px 2px rgba(15, 29, 102, 0.31));
    color: #0066cc;
    padding: 13px;
    text-align: left;
    font-size: 13px;
    font-weight: 700;
    line-height: 12px;
    display: flex;
    align-items: center;
  }

  .syw-vector sup {
    font-size: 13px;
    position: relative;
    top: 0;
    left: 1px;
  }
  .syw-vector-text {
    position: initial;
    width: 39px;
    height: 36px;
    left: 14px;
    top: 14px;
    letter-spacing: -0.5px;
  }
  .ovel-backdrop {
    height: 65px;
    width: 65px;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    font-weight: 900;
    background: #2c51a2;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 0.4rem;
  }
  .ovel-backdrop img {
    border-radius: 50%;
  }
  .paymentSuccessMessage {
    width: 343px;
    height: 100px;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #262c2f;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: auto;
  }

  .paymentSuccessNote {
    font-size: 12px;
    font-weight: 500;
    color: #909ba0;
  }
  .purchaseDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .purchaseDetails-title {
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    word-break: break-all;
  }

  .purchaseDetails-amount {
    font-size: 16px;
    font-weight: 500;
    background-color: white;
    border-radius: 1.25rem;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding: 0.5rem;
  }

  .purchaseDetails-amount.fonts-bold {
    font-weight: 600;
    font-size: 18px;
  }
  .paymentInformation {
    display: flex;
    align-items: center;
  }

  .paymentInformation .description {
    text-align: left;
    margin-left: 2rem;
  }
  .paymentInformation .paymentSuccessNote {
    font-size: 15px;
  }
}
.hs-offer-page-wrapper {
  .container-grey {
    padding: 1.5rem 1.5rem 0.5rem;
    background-color: #edf1f5;
  }
  .hs-offer-header {
    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: 700;
    margin: 0;
    color: #262c2f;
  }
  .hs-offer-sb1 {
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 2rem;
  }
  .hs-shs-nsywao {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #292e33;
  }
  .shs-upper{
    padding-left: 1rem;
    padding-right: 1rem ;
  }
  .hs-shs-header-main{
    display: flex;
    gap: 10px;
    align-items: center;
    p{
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-align: center;

    }
    img{
      height: 48px;
      // width: 64px;
    }
  }
  .hs-shs-card-desc{
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #9098A0;
  }
  .hs-shs-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #292e33;
  }
  .hs-shs-sde {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #0066cc;
  }
  .hs-shs-btn-yes-decline {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .img-space {
    display: flex;
    flex-direction: column-reverse;
    margin: 1.375rem;
    align-items: center;
    justify-content: flex-end;
    margin: 2.5rem 1.5rem 1.5rem;
  }

  .img-space-alc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    margin: 2rem 0 2rem 0;
  }
  .img-mastercard {
    position: relative;
    height: 7.5rem;
    width: 10rem;
    margin-right: 2rem;
  }

  .img-eclipse {
    position: absolute;
    margin-left: 3.5rem;
    margin-top: 4px;
    height: 120px;
    width: 120px;
  }

  .btn-blk {
    color: #000000;
  }
}
.hs-onhold-wrapper{
    
}
@media only screen and (max-width: 495px) {
  .hs-description-wrapper {
    .container-sh {
      padding: 1.5rem 0 1.5rem 0;
    }
  }
  .hs-offer-page-wrapper {
    .container-grey {
      padding: 1rem;
      background-color: #edf1f5;
    }
  }
}
