.custom-summary-wrapper {
  .container-cc {
    margin-top: 28px;
    display: flex;
    .cc {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.625rem;
      height: 1.625rem;
      border-radius: 0.375rem;
      border: 0.0625rem solid #bfc9d4;
      margin: 0 0.75rem 0.5rem 0;
    }
  }
  .input-label {
  }
  .another-card-btn {
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 11px;
    border: 1px solid #5e6a70;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition */
    .sup-without-fonts {
      font-family: sans-serif;
      font-size: 0.6rem;
      top: 2px;
      right: 9px;
    }
    img {
      height: 15px;
      width: 24px;
    }
  }

  .another-card-btn:hover {
    background-color: #8080801f;
  }

  .right-arrow-another-card {
    position: absolute;
    right: 1rem;
  }
}
.summary-wrapper {
  .progress-bar-summery {
    margin-top: 20px;
  }
  .ECM-selection-card {
    border: 2px solid #e7ebee;
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    background-color: transparent;
    width: 100%;
    transition: border-color 0.25s ease-in;
    position: relative;
    height: max-content;
    align-items: flex-start;
    &.selected {
      border-color: #0066cc;
    }

    &.no-select {
      justify-content: center;
    }

    .description {
      text-align: left;
      margin-left: 14px;
      margin-top: -5px;
    }
    .card-digits {
      margin-bottom: 16px;
    }
    .cvv-line {
      border-bottom: 2px solid #e2e3e3;
      padding-bottom: 16px;
    }

    .cvv-input {
      display: flex;
      align-items: center;
      padding-bottom: 0.2rem;

      p {
        font-size: 12px;
        color: #262c2f;
      }
    }

    .expiry-line {
      border-bottom: 2px solid #e2e3e3;
    }
  }
  .ECM-allign {
    display: flex;
    align-items: flex-start;
  }
  .another-card-btn {
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 11px;
    border: 1px solid #5e6a70;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition */
  }

  .another-card-btn:hover {
    background-color: #8080801f;
  }

  .right-arrow-another-card {
    position: absolute;
    right: 1rem;
  }
  @media screen and (max-width: 494px) {
    .summary-card-img {
      margin-top: 16px;
    }
    .card-digits {
      margin-bottom: 2px;
    }
  }
}
.card-miniloader{
  position: absolute;
  top: 17px;
  right: 11px;
}
.greentick-cc{
  margin-top: -6px;
}
